import React from "react";
import styled from "styled-components";

import Wrapper from "../components/wrapper";

import Layout from "../components/layout";

const Mentions = () => (
    <Layout>
        <Wrapper>
            <Content>
                <h1>Mentions Légales</h1>
                <h3>Éditeur</h3>
                <p>Ce site est édité par la société DOCAPOSTE Agile-IT, filiale de DOCAPOSTE (Groupe La Poste).</p>
                <h3>Siège social</h3>
                <p>
                    1&2 bis Rue Antoine L Lavoisier 76300 SOTTEVILLE-LES-ROUEN
                    <br /> Capital social : 69 705 520 euros R.C.S
                    <br /> ROUEN 53158388800031
                </p>
                <h3>Directeur de la publication</h3>
                <p>Roland ANDRE</p>
                <h3>Siège social de l’hébergeur</h3>
                <p>
                    Le site est hébergé par OVH, 2 Rue Kellermann 59100 ROUBAIX Tél : 09 72 10 10 07
                    <br /> Le site peut avoir recours à des cookies.
                    <br /> Les informations ainsi recueillies feraient alors l’objet d’un traitement informatique
                    destiné à répondre aux demandes d’informations et commerciales. Les destinataires des données sont :
                    DOCAPOST Agility.
                    <br /> Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, vous
                    bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent, que vous
                    pouvez exercer en vous adressant à : DOCAPOST Agility 1&2 bis Rue Antoine L Lavoisier 76300
                    SOTTEVILLE-LES-ROUEN.
                    <br /> Vous pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous
                    concernant.
                    <br /> © 2017 DOCAPOST Agility – Tous droits réservés – Reproduction (partielle ou intégrale)
                    interdite sans autorisation.
                </p>
            </Content>
        </Wrapper>
    </Layout>
);
const Content = styled.section`
    h3 {
        color: blue;
    }
`;
export default Mentions;
